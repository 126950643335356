<template>
  <div class="carousel">
    <div class="carousel-container">
      <img 
        v-for="(image, index) in images" 
        :key="index" 
        :src="image" 
        :class="['carousel-image', { active: currentImageIndex === index }]" 
        alt="轮播图片" 
      />
    </div>
    <div class="carousel-dots">
      <span 
        v-for="(image, index) in images" 
        :key="index" 
        :class="['dot', { active: currentImageIndex === index }]"
        @click="goToImage(index)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PictureSlider",
  data() {
    return {
      images: [
        require("../../assets/feiji/jump.jpg"),
        require("../../assets/feiji/panda.jpg"),
        require("../../assets/feiji/water.jpg"),
        require("../../assets/feiji/xueshan.jpg"),
        require("../../assets/feiji/zhouxi.jpg"),
        require("../../assets/feiji/可爱.jpg"),
        require("../../assets/feiji/梨花.jpg"),
      ],
      currentImageIndex: 0, // 当前显示的图片索引
      timer: null, // 自动轮播的定时器
    };
  },
  methods: {
    startCarousel() {
      this.timer = setInterval(() => {
        this.nextImage();
      }, 4000); // 每3秒切换图片
    },
    pauseCarousel() {
      clearInterval(this.timer);
    },
    nextImage() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    },
    goToImage(index) {
      this.currentImageIndex = index;
    },
  },
  mounted() {
    this.startCarousel();
  },

};
</script>

<style scoped>
.carousel {
  position: relative;
  width: 90vw;  /* 宽度设置为屏幕宽度的 100% */
  height: 700px;  /* 高度设置为屏幕高度的 60% */
  overflow: hidden;
  margin: 0 auto;
}

.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: left 0.5s ease-in-out;
  opacity: 0;
}

.carousel-image.active {
  left: 0;
  opacity: 1;
}

.carousel-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: lightgray;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: gray;
}
</style>
