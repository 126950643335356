<template>
  <div>
    <h1>飞机</h1>
    <PictureSlider />
    <h1 class="dynamic-color">{{ msg }}</h1>
  </div>
</template>

<script>
import PictureSlider from './picture/PictureSlider.vue';

export default {
  name: "IndexPage",
  data() {
    return {
      msg: "行则将至 道阻且长",
    };
  },
  components: {
    PictureSlider,
  },
};
</script>


<style scoped>
h3 {
  margin: 40px 0 0;
}

.dynamic-color {
  font-size: 24px;
  font-weight: bold;
  animation: colorChange 3s infinite;
  /* 动画 3 秒循环播放 */
}

@keyframes colorChange {
  0% {
    color: red;
  }

  33% {
    color: blue;
  }

  66% {
    color: green;
  }

  100% {
    color: red;
  }
}
</style>
